header{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 40px 0 20px 0;
    z-index: 10;

    @include media('<desktop'){
        padding: 35px 0 20px 0;
        
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $purpule;
            z-index: 3;
            opacity: 0;
            transition: opacity 0s ease 0;
    
            .active &{
                transition: opacity 0s ease .5s;
                opacity: 1;
            }
        }
    }

    @include media('<phone'){
        padding: 20px 0;
    }
    
    .container{
        max-width: 1330px;
    }

    .content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    button{
        border: none;
        background-color: transparent;
    }
}

.menu-name{
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    @include adaptiv-vaule("top", 150, 100, 1);
    left: 0;
    @include adaptiv-vaule("width", 300, 50, 1);
    @include adaptiv-vaule("height", 370, 100, 1);
    overflow: hidden;

    @include media('<desktop'){
        display: flex;
    }

    @include media('<phone'){
        display: none;
    }

    span{
        position: absolute;
        top: 0;
        @include adaptiv-vaule("left", -55, -20, 1);
        font-weight: bold;
        @include adaptiv-vaule("font-size", 230, 50, 1);
        color: $white;
        line-height: .8;
        z-index: -1;
        color: transparent;
        -webkit-text-stroke: 1px $white;
        text-stroke: 1px $white;
        opacity: 17%;
    }

    p{
        font-weight: bold;
        @include adaptiv-vaule("font-size", 60, 20, 1);
        color: $white;
        margin: 0;
        z-index: 2;
    }
}

.logo{
    width: 192px;
    height: 25px;
    z-index: 10;

    @include media('<phone'){
        width: 100px;
        height: 14px;
    }

    path{
        fill: transparent;
        stroke: $white;
        stroke-dasharray: 87;
        stroke-dashoffset: 87;
        animation: logo 5s forwards;
    }
    
    @keyframes logo {
        0%{
            stroke-dashoffset: 0;
            stroke-width: 0;
        }
    
        1%{
            stroke-dashoffset: 87;
            stroke-width: 0;
        }
    
        50%{
            stroke-dashoffset: 174;
            fill: transparent;
            stroke-width: 1;
        }
    
        100%{
            stroke-dashoffset: 174;
            fill: $white;
        }
    }
}

.controls{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media('<desktop'){
        position: relative;
        padding: 0 25px;
    }
}


.arrows{
    position: relative;
    width: 20px;
    height: 9px;
    margin-left: 10px;
    transition: all .3s ease 0s;
    
    @include media('<desktop'){
        position: absolute;
        top: 33px;
        right: -30px;
        width: 30px;
        height: 15px;
    }   

    @include media('<tablet'){
        top: 18px;
    } 

    @include media('<phone'){
        top: 11px;
        right: -20px;
    } 
    
    span{
        position: absolute;
        width: 6px;
        height: 1px;
        background-color: $white;
        top: 5px;
        left: 5px;
        transition: all .3s ease 0s;

        &:first-child{
            transform:  rotate(45deg);
        }

        &:last-child{
            transform: translate(4px)  rotate(-45deg);
            
            @include media('<desktop'){
                transform: translate(8px)  rotate(-45deg);
            }

            @include media('<tablet'){
                transform: translate(6px)  rotate(-45deg);
            }

            @include media('<phone'){
                transform: translate(5px)  rotate(-45deg);
            }
        }

        @include media('<desktop'){
            width: 15px;
            height: 4px;
        }

        @include media('<tablet'){
            width: 10px;
            height: 3px;
        }

        @include media('<phone'){
            width: 8px;
            height: 2px;
        }
    }
}

.menu-wrap{
    @include media('<desktop'){
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 100vh;
        overflow: auto;
        top: -150%;
        left: 0;
        background: url('../images/burger-menu-bg.png') 100% 100% no-repeat;
        background-color: $purpule;
        transition: all .5s ease 0s;
        z-index: 2;
        padding-top: 80px;
    }

    @media (max-height:800px){
        justify-content: flex-start;
    }    
}

.menu_active{
    @include media('<desktop'){
        top: 0;
    }
}

.menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;

    @include media('<desktop'){
        flex-direction: column;
    }
}

.menu-item{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;

    @include media('>=desktop') { 
        &:hover{
            .sub-menu{
                transform: translate(0, 0);
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
            }

            .arrows{
                span{
                    transition: all .3s ease 0s;
                    background-color: $yellow;

                    &:first-child{
                        transform:  rotate(-45deg);
                    }
            
                    &:last-child{
                        transform: translate(4px)  rotate(45deg);
                    }
                }
            }

            .link{
                color: $yellow;
            }
        }

        .contact-btn{
            &:hover{
                color: $white;
            }
        }

        .link{
            transition: all .3s ease 0s;
        }
    }

    @include media('<desktop'){
        flex-direction: column;
        margin: 0;
        margin-bottom: 50px;

        .contact-btn{
            background: none;
        }
    }

    @include media('<tablet'){
        margin-bottom: 35px;
    }

    @include media('<phone'){
        margin-bottom: 25px;
    }

    a{
        transition: color .3s ease 0s;
        transition: opacity 1s ease 0s;
        font-size: 15px;
        font-weight: bold;
    
        @include media('<desktop'){
            font-size: 55px;
        }
    
        @include media('<tablet'){
            font-size: 35px;
        }
    
        @include media('<phone'){
            font-size: 25px;
        }
    }
}

.menu-item_active {
    @include media('<desktop'){
        .sub-menu{
            max-height: 500px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transition: all .7s ease 0s;
            text-align: center;
            margin-top: 30px;
        }

        .arrows{
            span{
                transition: all .3s ease 0s;

                &:first-child{
                    transform:  rotate(-45deg);
                }
        
                &:last-child{
                    transform: translate(4px)  rotate(45deg);

                    @include media('<desktop'){
                        transform: translate(8px)  rotate(45deg);
                    }

                    @include media('<tablet'){
                        transform: translate(6px)  rotate(45deg);
                    }

                    @include media('<phone'){
                        transform: translate(5px)  rotate(45deg);
                    }
                }
            }
        }
    }
}

.opacity-links{
    .menu-item{
        a{
            transition: all .3s ease 0s;
            opacity: 20%;
        }
    }

    .menu-item_active{
        a{
            opacity: 100%;
        }
    }
}

.main-item{
    display: none;

    @include media('<desktop'){
        display: block;
    }
}


.sub-menu{
    position: relative;
    list-style: none;
    padding: 15px 0;
    margin: 0;

    @include media('>=desktop'){
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 200px;
        visibility: hidden;
        transition: all .3s ease 0s;
        transform: translate(0, 10px);
        opacity: 0;
        pointer-events: none;
    }

    @include media('<desktop'){
        max-height: 0;
        min-width: 200px;
        visibility: hidden;
        transition: all .7s ease 0s;
        opacity: 0;
        pointer-events: none;
        text-align: center;
        padding: 0;

        li{
            &::after{
                display: none;
            }
        }
    }
}

.sub-item{
    &:not(:last-child){
        margin-bottom: 20px;
        position: relative;

        &::after{
            content:'';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 1px;
            background-color: white;
        }
    }

    @include media('<desktop'){
        &::after{
            display: none;
        }
    }

    a{
        font-size: 15px;
        
        @include media('>=desktop'){
            transition: all .3s ease 0s;

            &:hover{
                color: $yellow;
            }
        }

        @include media('<desktop'){
            font-size: 24px;
        }

        @include media('<desktop'){
            font-size: 18px;
        }

        @include media('<desktop'){
            font-size: 14px;
        }
    }
}

.social{
    display: none;

    @include media('<desktop'){
        display: block;
    }
    
    a{
        margin: 0 15px;

        @include media('<phone'){
            margin: 0 8px;
        }
    }

    svg{
        width: 40px;
        height: 38px;
        fill: $white;

        @include media('<tablet'){
            width: 35px;
            height: 32px;
        }

        @include media('<phone'){
            width: 30px;
            height: 23px;
        }
    }
}

/* ---------------burger */

.burger{
    display: none;
    position: relative;
    width: 30px;
    height: 15px;
    z-index: 5;

    @include media('<desktop'){
        display: block;
    }

    span{
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $white;
        border-radius: 10px;
        transition: all .7s ease 0s;
    }
    
    &::before, &::after{
        content:'';
        position: absolute;
        right: 0;
        height: 2px;
        width: 100%;
        background-color: $white;
        border-radius: 10px;
    }

    &::before{
        top: 0;
    }

    &::after{
        bottom: 0;
        width: 50%;
    }
}

.burger_active{

    span{
        opacity: 0;
    }
    
    &::before, &::after{
        animation-duration: .7s;
        animation-fill-mode: forwards;
    }
    
    &::before{
        animation-name: burger1;
    }

    &::after{
        animation-name: burger2;
    }

   @keyframes burger1 {
        0%{
            top: 0px;
        }

        40%{
            transform: rotate(0deg);
            top: 6px;
        }

        100%{
            transform: rotate(45deg);
            top: 6px;
        }
    }
    
    @keyframes burger2 {
        0%{
            bottom: 0;
        }

        40%{
            transform: rotate(0deg);
            bottom: 7px;
            width: 100%;
        }

        100%{
            transform: rotate(-45deg);
            bottom: 7px;
            width: 100%;
        }
    }
}

.burger_finish{

    &::before, &::after{
        animation-duration: .7s;
        animation-fill-mode: forwards;
    }

    &::before{
        animation-name: burger3;
    }

    &::after{
        animation-name: burger4;
    }

    @keyframes burger3 {
        0%{
            transform: rotate(45deg);
            top: 6px;
        }

        40%{
            transform: rotate(0deg);
            top: 6px;
        }

        100%{
            top: 0;
        }
    }
    
    @keyframes burger4 {
        0%{
            transform: rotate(-45deg);
            bottom: 6px;
            width: 100%;
        }

        40%{
            transform: rotate(0deg);
            bottom: 6px;
            width: 100%;
        }

        100%{
            bottom: 0;
        }
    }
}