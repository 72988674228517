.port{
    background-color: $purpule-bg;

    .container{
        @include media('<phone'){
           padding: 0;
        }
    }

    h2{
        margin-bottom: 20px;

        @include media('<phone'){
            padding: 0 15px;
        }
    }

    .item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media('<phone'){
            text-align: center;
        }
    }

    .item1{
        width: 38%;

        @include media('<Xtablet'){
            width: 48%;
        }

        @include media('<tablet'){
            width: 100%;
        }
    }

    .item2{
        width: 58%;

        @include media('<Xtablet'){
            width: 48%;
        }

        @include media('<tablet'){
            display: none;
        }
    }

    .item-wrap{
        display: flex;
        justify-content: space-between;
        padding: 50px 0;

        @include media('<tablet'){
            padding: 30px 0;
        }

        &:nth-child(odd){
            .item1{
                width: 58%;
        
                @include media('<Xtablet'){
                    width: 48%;
                }
        
                @include media('<tablet'){
                    width: 100%;
                }
            }
        
            .item2{
                width: 38%;
        
                @include media('<Xtablet'){
                    width: 48%;
                }
        
            }
        }
    }


    .img{
        height: 340px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 20px;

        @include media('<tablet'){
            height: 440px;
        }

        @include media('<phone'){
            height: 250px;
        }
    }

    .text{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        p{
            font-weight: bold;
            @include adaptiv-vaule("font-size", 28, 18, 1);
            line-height: 119%;
            padding-right: 15px;

            @include media('<phone'){
                padding: 0 15px;
                margin: 0 auto;
            }
        }

        span{
            font-size: 18px;
            font-weight: normal;
            color: $white;
            text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 100px #ffffff, 0 0 10px #15F2FD, 0 0 50px #15F2FD, 0 0 10px #15F2FD, 0 0 0px #15F2FD, 0 0 1px #15F2FD;
            white-space: nowrap;

            @include media('<phone'){
                display: none;
            }
        }
    }
}