body {
    font-family: 'Poppins', sans-serif;
    background-color: $main-black;
}

.body_lock{
    overflow: hidden;
}

#main{
    overflow: hidden;
}

.container{
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 15px;
}

.main-btn{
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 191px;
    padding: 13px 15px;
    border: 1px solid $white;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    z-index: 1;
    overflow: hidden;
    transition: all .3s ease 0s;
    
    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0.25turn, rgba(252, 31, 31, 1), rgba(252, 147, 21, 1));
        transition: all .3s ease 0s;
        z-index: -1;
        opacity: 0;
    }

    &:hover{
        border: 1px solid transparent;

        &::before{
            opacity: 1;
        }
    }

    img{
        margin-left: 40px;
    }
}

.contact-btn{
    text-align: center;
    min-width: 191px;
    padding: 13px 15px;
    border-radius: 5px;
    background-color: $purpule-text;
    transition: all .3s ease 0s;

    &:hover{
        background-color: $purpule;
        color: $white;
    }
}

.icon{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid $blue-light;
    border-radius: 50%;
    background-color: transparent;

    &::after{
        content:'';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        width: 1px;
        height: 1px;
        background-color: $blue-light;
        box-shadow: 0px 0px 25px 18px $blue-light;
        z-index: -1;
    }
}

.wing{
    position: absolute;
    right: -50%;
    width: 880px;
    height: 580px;
    animation-name: wing;
    animation-duration: 50s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.wing-revers{
    animation-direction: reverse;
}

@keyframes wing {
    0%{
        right: -50%;
    }

    100%{
        right: 100%;
    }
}

.wingS{
    animation-name: wingS;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes wingS {
    0%{
        right: 0;
    }

    100%{
        right: 40%;
    }
}

.linear{
    position: relative;
    @include adaptiv-vaule("min-height", 300, 130, 1);
    background: $main-black;
    background: linear-gradient(180deg, $main-black 0%, $purpule-bg 100%);
}

.linear-revers{
    background: linear-gradient(0deg, $main-black 0%, $purpule-bg 100%);
}

.big-text{
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 25%;
    @include adaptiv-vaule("left", -100, -30, 1);
    overflow: hidden;

    @include media('<phone'){
        top: 10%;
    }

    p{
        @include adaptiv-vaule("font-size", 288, 96, 1);
        font-weight: bold;
        color: $white;
        line-height: .8;
        z-index: 1;
        color: transparent;
        text-transform: uppercase;
        -webkit-text-stroke: 1px $white;
        text-stroke: 1px $white;
        opacity: 17%;
    }
}

.slick-dots{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    z-index: 2;

    li{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #C4C4C4;
        margin: 0 6px;
        transition: all .3s ease 0s;
        cursor: pointer;
    }

    .slick-active{
        background: #fff;
        transition: all .3s ease 0s;
    }
    
    button{
        border: none;
        background: none;
    }
}

.slick-track{
    display: flex;
    align-items: flex-start;
}

.pages{
    position: relative;
    padding: 90px 0;

    .container{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: calc(100vh - 180px);
        z-index: 3;

        @include media('<phone'){
            padding: 0;
        }
    }
}

.page-main{
    
    .port, .testi{
        background-color: $main-black;

        h2{
            font-size: 32px;
        }
    }
}

.img{
    position: relative;
    overflow: hidden;

    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.img-anim{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;

    &::before, &::after{
        content:'';
        position: absolute;
        width: 120px;
        height: 0px;
        z-index: -1;

        animation-name: bl;
        animation-duration: 4s;
        animation-iteration-count: infinite;
    }

    &::before{
        top: 100px;
        right: 40%;
        box-shadow: 0 0 180px 85px #fab2ff;
    }

    &::after{
        bottom: 50px;
        right: 55%;
        box-shadow: 0 0 180px 45px #1904e5;
    }

    @keyframes bl {
        0%{
            opacity: 1;
        }
    
        4% {
            opacity: 0.9;
        }
    
        6% {
            opacity: 0.85;
        }
    
        8% {
            opacity: 0.95;
        }
    
        10% {
            opacity: 0.9;
        }
    
        11% {
            opacity: 0.5;
        }
    
        12% {
            opacity: 0.9;
        }
    
        14% {
            opacity: 0.95;
        }
    
        16% {
            opacity: 0.98;
        }
    
        17% {
            opacity: 0.9;
        }
    
        19% {
            opacity: 0.93;
        }
    
        20% {
            opacity: 0.99;
        }
    
        24% {
            opacity: 1;
        }
    
        26% {
            opacity: 0.94;
        }
    
        28% {
            opacity: 0.98;
        }
    
        37% {
            opacity: 0.93;
        }
    
        38% {
            opacity: 0.5;
        }
    
        39% {
            opacity: 0.96;
        }
    
        42% {
            opacity: 1;
        }
    
        44% {
            opacity: 0.97;
        }
    
        46% {
            opacity: 0.94;
        }
    
        56% {
            opacity: 0.9;
        }
    
        58% {
            opacity: 0.9;
        }
    
        60% {
            opacity: 0.99;
        }
    
        68% {
            opacity: 1;
        }
    
        70% {
            opacity: 0.9;
        }
    
        72% {
            opacity: 0.95;
        }
    
        93% {
            opacity: 0.93;
        }
    
        95% {
            opacity: 0.95;
        }
    
        97% {
            opacity: 0.93;
        }

        100%{
            opacity: 1;
        }
    }

    .wing1 {
        position: absolute;
        top: -80px;
        right: 0;
        z-index: -1;

        @include media('<phone'){
            top: 0;
        }
    }

    .wing2{
        position: absolute;
        top: 47%;
        z-index: 2;
    }
    
    .servis-icon{
        position: relative;
        z-index: 1;
    }
}

/* -----------------------------main------------------------- */

.main{
    position: relative;
    padding: 90px 0;
    background-color: hsla(0, 0%, 4%, 0.80);

    video, iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .container{
        min-height: calc(100vh - 180px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media('<desktop'){
            justify-content: flex-end;
        }
    }

    .content{
        max-width: 50%;

        @include media('<tablet'){
            max-width: 55%;
        }

        @include media('<600px'){
            max-width: 100%;
        }
    }

    h1{
        @include adaptiv-vaule("font-size", 55, 24, 1);
        margin-bottom: 30px;
    }

    p{
        @include adaptiv-vaule("font-size", 18, 14, 1);
        line-height: 1.5;
        margin-bottom: 50px;
    }

    .main-btn{

        @include media('<phone'){
            display: block;
            max-width: 191px;
            margin: 0 auto;
        }
    }
}

/* -----------------------------servis------------------------- */

.servis{
    position: relative;
    padding: 50px 0 60px 0;

    .container{
        @include media('<phone'){
            padding: 0;
        }
    }

    .wing{
        top: 0;
    }

    .container{
        position: relative;
    }

    h2{
        margin-bottom: 75px;

        @include media('<phone'){
            padding: 0 15px;
        }
    }

    .item-cont{
        display: flex;
        justify-content: space-between;
    }

    .first-item{
        width: 30%;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        background-color: #242424;
        padding: 50px 45px;
        border: 1px solid #E0E0E0;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @include media('<desktop'){
            width: 38.5%;
        }

        @include media('<tablet'){
            display: none;
        }

        .title{
            animation-duration: 1s;
            span{
                display: block;
                color: $purpule-text;
                font-size: 15px;
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            p{
                font-weight: bold;
                font-size: 40px;
                line-height: 1.2;
            }
        }

        .description{
            font-size: 15px;
            line-height: 115.5%;
            animation-duration: 1s;

            span{
                color: $purpule-text;
            }
        }
    }

    .first-item_active{
        display: flex;
        
        @include media('<tablet'){
            display: none;
        }
        
        .title, .description{
            animation-name: hoverItem;
        }
    }

    @keyframes hoverItem {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }

    .item-wrap{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 69.5%;

        @include media('<desktop'){
            width: 61%;
        }

        @include media('<tablet'){
            width: 100%;
            display: block;
        }
    }

    .itemU{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 49.5%;
        border: 1px solid #E0E0E0;
        border-top-right-radius: 5px;
        padding: 25px 20px;
        margin-bottom: 5px;

        &:nth-child(3), &:nth-child(4){
            margin-bottom: 0;
        }

        &:nth-child(3){

            @include media('<desktop'){
               margin-bottom: 5px;
            }

            @include media('<tablet'){
                margin-bottom: 0;
            }
        }

        @include media('<desktop'){
            width: 100%;
        }
        
        @include media('<tablet'){
            text-align: center;
            max-width: 100%;
            margin: 0 20px 0 20px;
        }

        @include media('<phone'){
            margin: 0;
            border: none;
        }
        
        .icon{
            margin-bottom: 17px;

            @include media('<tablet'){
                margin: 0 auto;
                margin-bottom: 17px;
            }
        }

        p{
            font-weight: bold;
            font-size: 33px;
            line-height: 119%;

            @include media('<tablet'){
                font-size: 28px;
            }
        }
    }
}

.servis-slider{
    overflow: hidden;

    .slick-track{
        align-items: stretch;

        @include media('<phone'){
            border: 1px solid #E0E0E0;
            border-left: none;
            border-right: none;
        }
    }

    .slick-dots{
        margin-top: 50px;
    }
}

/* -----------------------------progress------------------------- */

.progress{
    padding: 60px 0 0 0;
}

.progress-content{
    display: flex;
    justify-content: space-between;

    @include media('<desktop'){
        flex-wrap: wrap;
        justify-content: center;
    }
}

.progress-circle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 208px;
    max-width: 208px;
    min-height: 208px;
    max-height: 208px;
    border-radius: 50%;
    overflow: hidden;

    @include media('<desktop'){
        margin: 40px 8%;
    }

    @include media('<phone'){
        margin: 40px 10px;
    }

    &::before{
        content:'';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(56,19,194);
        background: linear-gradient(180deg, rgba(56,19,194,1) 0%, rgba(255,111,216,1) 100%);
        z-index: -1;
    }
}

.progress-title{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
    width: 80%;
    height: 80%;
    background: rgb(16,16,16);
    background: linear-gradient(45deg, rgba(16,16,16,1) 0%, rgba(31,31,31,1) 100%);
    border-radius: 50%;
    
    span{
        font-weight: bold;
        font-size: 55px;
        color: #5E3DA6;
    }

    p{
        margin: 0;
    }
}

.progress_active{
    .progress-circle{
        &::before{
            animation-name: progress;
            animation-duration: 2s;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes progress {
    0%{
        bottom: -110%;
    }

    100%{
        bottom: 0;
    }
}

/* -----------------------------clients------------------------- */

.clients{
    padding: 110px 0 40px 0;
    background-color: $purpule-bg;

    .content{
        display: flex;
        justify-content: space-between;

        @include media('<phone'){
            flex-direction: column;
            align-items: center;
        }
    }

    .text{
        width: 45%;
        max-width: 400px;

        @include media('<phone'){
            width: 100%;
            max-width: 100%;
            margin-bottom: 85px;
        }

        h2{
            @include adaptiv-vaule("margin-bottom", 65 , 35, 1);
        }

        p{
            font-size: 15px;
            line-height: 115.5%;
            letter-spacing: 0.05rem;
        }
    }

    .item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        min-width: 100%;
        padding: 0 15px;
        margin-bottom:80px;

        .img-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 70px;
            height: 65px;
        }

        p{
            font-weight: 800;
            font-size: 15px;
            line-height: 130%;
            text-transform: uppercase;
            margin: 0;
        }
    }
}

.icons-slider{
    max-width: 50%;
    overflow: hidden;

    @include media('<phone'){
        max-width: 100%;
    }

    .slick-slide{
        display: flex;

        @include media('<tablet'){
            flex-direction: column;
        }
    }
}

/* -----------------------------lastnews------------------------- */

.lastnews{
    @include adaptiv-vaule("padding-bottom", 180, 60, 1);

    .container{
        @include media('<phone'){
            padding: 0;
        }
    }

    .content{
        @include media('<phone'){
            text-align: center;
        }

    }

    h2{
        @include media('<phone'){
            text-align: left;
            padding: 0 15px;
        }
    }

    .item-wrap{
        display: flex;
        justify-content: space-between;
    }

    .item{
        position: relative;
        max-width: 361px;
        width: 32%;

        @include media('<phone'){
            margin-bottom: 40px;
        }

        p{
            font-weight: bold;
            @include adaptiv-vaule("font-size", 28, 18, 1);
            margin-bottom: 20px;
        }

        span{
            display: block;
            font-size: 15px;
            color: $purpule-text;
        }

        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color:  $main-black;
            font-size: 0;
            color: transparent;
            opacity: 0;
            transition: all .3s ease 0s;

            &:hover{
                opacity: 50%;
            }
        }

        @include media('<desktop'){
            width: 48%;

            &:last-child{
                display: none;
            }
        }

        @include media('<tablet'){
            width: 100%;
            max-width: 100%;
            height: 420px;

            &:nth-last-child(-n+2){
                display: none;
            }
        }
    }

    .img{
        width: 100%;
        height: 360px;
        margin-bottom: 20px;
    }

    .btn-wrap{
        display: none;

        @include media('<phone'){
            display: block;
        }
    }
}

/* -----------------------------about-page------------------------- */

.about{
    
    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media('<tablet'){
            flex-direction: column;
        }
    }

    h1{
        @include adaptiv-vaule("font-size", 55, 36, 1);
    }

    img{
        @include media('<phone'){
            min-width: 500px;
        }
    }

    .wing{
        top: 20%;
    }
}

/* -----------------------------bio------------------------- */

.bio{
    padding: 0 0 70px 0;

    p, li{
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 300;
        line-height: 1.5;
        color: $white;
    }

    h3{
        @include media('<phone'){
            text-align: center;
        }
    }

    .description{
        @include adaptiv-vaule("margin-bottom", 60 , 30, 1);
    }

    .block{
        display: flex;
        justify-content: space-between;

        @include media('<desktop'){
            flex-direction: column;
        }
    }
    
    .text{
        margin-right: 20px;

        @include media('<desktop'){
            margin: 0 0 150px 0;
        }
    }

    .block-list{
        @include adaptiv-vaule("margin-bottom", 60 , 30, 1);
    }

    .progress-content{
        flex-wrap: wrap;
        max-width: 460px;
        min-width: 460px;

        @include media('<desktop'){
            max-width: 100%;
            min-width: 0;
        }

        .progress-circle{

            &:nth-child(1),  &:nth-child(2){
                margin-bottom: 35px;
            }
        }
    }
}

/* -----------------------------skills------------------------- */

.skills{
    padding: 70px 0 130px 0;

    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media('<desktop'){
            align-items: flex-start;
        }

        @include media('<650px'){
            flex-direction: column;
            align-items: center;
        }
    }

    .description{
        width: 27%;

        @include media('<desktop'){
            width: 48%;
        }

        @include media('<650px'){
            width: 100%;
            margin-bottom: 45px;
        }
        
        p{
            @include adaptiv-vaule("font-size", 20, 16, 1);
            font-weight: 300;
            line-height: 1.5;
        }
    }   

    .block{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 70%;
        padding: 0 0 0 60px;
        border-left:1px solid $white;

        @include media('<desktop'){
            flex-direction: column;
            width: 49%;
        }

        @include media('<tablet'){
            padding: 0 0 0 20px;
        }

        @include media('<650px'){
            width: 100%;
            border: none;
            align-items: center;
            padding: 0;
        }
    }

    .item{
        display: flex;
        align-items: center;
        width: 48%;

        @include media('<desktop'){
            width: 100%;
        }

        @include media('<650px'){
            width: 80%;
        }

        @include media('<phone'){
            width: 100%;
        }

        p{
            font-weight: bold;
            font-size: 20px; 
            margin: 0;
        }
    }

    .img-wrap{
        display: flex;
        justify-content: center;
        max-width: 110px;
        min-width: 110px;
    }
}

/* -----------------------------services------------------------- */

.services{
    position: relative;

    .container{
        max-width: 1440px;
        padding: 0;
    }

    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media('<tablet'){
            flex-direction: column;
        }
    }

    h1{
        padding-left: 10%;

        @include media('<desktop'){
            padding-left: 15px;
        }

        @include media('<tablet'){
            padding: 0;
            margin-bottom: 65px;
        }
    }

    .img{
        width: 57%;
        height: 500px;
        border-radius: 10px 0px 0px 10px;

        @include media('<1250px'){
            height: 320px;
        }

        @include media('<tablet'){
            width: 100%;
            height: 490px;
            border-radius: 0;
        }
    }

    .wing{
        bottom: -130px;
    }
}

/* -----------------------------digital------------------------- */

.digital{
    padding: 50px 0 0 0;

    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 150px;

        &:nth-child(even){
            flex-direction: row-reverse;

            @include media('<tablet'){
                flex-direction: column-reverse;
            }

            .img-wrap{
                justify-content: flex-start;

                @include media('<tablet'){
                    justify-content: center;
                }
            }
        }

        @include media('<tablet'){
            flex-direction: column-reverse;
        }
    }

    .text{
        width: 64%;

        @include media('<tablet'){
            width: 100%;
        }

        @include media('<phone'){
            text-align: center;
        }

        h2{
            @include adaptiv-vaule("font-size", 48, 28, 1);
            margin-bottom: 30px;
            max-width: 475px;
            line-height: 119%;

            @include media('<tablet'){
                max-width: 100%;
            }
        }

        p{
            @include adaptiv-vaule("font-size", 18, 16, 1);
            line-height: 115.5%;
            margin-bottom: 40px;
            font-weight: 300;

            @include media('<phone'){
                text-align: left;
            }
        }
    }

    .btn-wrap{
        a{
            margin-right: 30px;

            @include media('<phone'){
                margin: 15px;
            }
        }
    }

    .img-wrap{
        display: flex;
        justify-content: flex-end;
        width: 34%;

        @include media('<tablet'){
            justify-content: center;
            margin-bottom: 50px;
            width: 100%;
        }
    }
}

/* -----------------------------service-pages------------------------- */

.service-pages{
    position: relative;

    .container{
        max-width: 1330px;
    }

    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media('<tablet'){
            flex-direction: column;
        }
    }

    .wing{
        bottom: -130px;
    }
}

/* -----------------------------full-digital------------------------- */

.full-digital{
    padding: 30px 0 65px 0;

    .title{
        margin-bottom: 60px;

        @include media('<phone'){
            text-align: center;
        }

        h3{
            @include adaptiv-vaule("font-size", 35, 28, 1);
            margin-bottom: 10px;
        }

        p{
            @include adaptiv-vaule("font-size", 18, 14, 1); 
        }
    }

    .description{
        @include adaptiv-vaule("margin-bottom", 90 , 40, 1);

        p{
            @include adaptiv-vaule("font-size", 18, 16, 1);
            font-weight: 300;
            line-height: 115.5%; 
        }
    }

    .btn-wrap{
        text-align: center;
    }
}

/* -----------------------------full-digital-icon------------------------- */

.full-digital-icon{
    position: relative;
    padding: 65px 0 60px 0;

    .wing{
        top: -200px;
    }

    h3{
        @include adaptiv-vaule("margin-bottom", 90 , 35, 1);

        @include media('<tablet'){
            text-align: center;
        }
    }

    .block{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30%;
        margin-bottom: 70px;

        @include media('<desktop'){
            width: 43%;
        }

        @include media('<tablet'){
            width: 100%;
            justify-content: center;
        }

        img{
            margin-right: 15px;
        }

        p{
            @include adaptiv-vaule("font-size", 18, 16, 1);
            line-height: 115.5%;
            margin: 0;
        }
    }
}

/* ///////////////////////tabs/////////////////////// */

.tabs__item-wrap{
    position: relative;
    padding-top: 50px;
    transition: all 0.3s ease;

    &::after{
        content:'';
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $purpule;
    }
}

.tabs__nav-btn{
    position: absolute;
    top: 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 20px;
    color: $white;

    @include media ('>=tablet'){

        &::after{
            content:'';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 0;
            height: 4px;
            background-color: #FAB2FF;
            border-radius: 10px;
            transition: all .7s ease 0s;
            box-shadow: 0px 5px 30px 5px #FAB2FF;
            z-index: 2;
            
            animation-name: blicks;
            animation-duration: 5s;
            animation-iteration-count: infinite;
        }

        @keyframes blicks {
            0%{
                box-shadow: 0px 5px 30px 2px #FAB2FF;
            }
            25%{
                box-shadow: 0px 5px 25px 2px #FAB2FF;
            }
            50%{
                box-shadow: 0px 5px 30px 2px #FAB2FF;
            }
            75%{
                box-shadow: 0px 5px 25px 2px #FAB2FF;
            }
            100%{
                box-shadow: 0px 5px 30px 2px #FAB2FF;
            }
        }
    }

    @include media ('<tablet'){
        position: relative;
        display: block;
        min-width: 310px;
        text-align: left;
        padding: 15px 50px 15px 15px;
        background-color: #1D2525;
        margin: 20px auto;
        transition: all 0.5s ease;

        &::before, &::after{
            content:'';
            position: absolute;
            top: 25px;
            right: 20px;
            width: 10px;
            height: 2px;
            transition: all 0.5s ease;
            background-color: $purpule-text;
        }

        &::before{
            transform: rotate(90deg);
        }

        &::after{
            transform: rotate(0);
        }
    }
}

.tab_btn1{
    left: 5%;

    @include media ('<tablet'){
        left: 0;
    }
}

.tab_btn2{
    left: 34%;

    @include media ('<tablet'){
        left: 0;
    }
}

.tab_btn3{
    left: 62%;

    @include media ('<tablet'){
        left: 0;
    }
}

.tab_btn4{
    right: 5%;

    @include media ('<tablet'){
        right: 0;
    }
}

.tab_active{

    @include media ('>=tablet'){

        &::after{
            transition: all 0.7s ease;
            width: 100%;
        }
    }

    @include media ('<tablet'){
        transition: all 0.5s ease;

        &::before, &::after{
            transition: all 0.5s ease;
        }

        &::before{
            transform: rotate(-135deg);
        }

        &::after{
            transform: rotate(135deg);
        }
    }
}

.tabs__item{
    transition: opacity 1s ease;
    max-height: 0;
    opacity: 0;
    visibility: hidden;

    @include media ('<tablet'){
        border: none;
    }
}

.tab__item_active{
    max-height: 99999px;
    visibility: visible;
    opacity: 1;
    transition: opacity 2s ease;

    @include media ('<tablet'){
        transition: max-height 30s ease, opacity 2s ease;
    }
}

/* -----------------------------portfolio------------------------- */

.portfolio{

    .container{
        @include media('<phone'){
            padding: 0;
        }
    }

    .item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include media('<tablet'){
            margin-bottom: 60px;
        }

        @include media('<phone'){
            text-align: center;
        }
    }

    .item1{
        width: 38%;

        @include media('<Xtablet'){
            width: 48%;
        }

        @include media('<tablet'){
            width: 100%;
        }
    }

    .item2{
        width: 58%;

        @include media('<Xtablet'){
            width: 48%;
        }

        @include media('<tablet'){
            width: 100%;
        }
    }

    .item-wrap{
        display: flex;
        justify-content: space-between;
        padding: 50px 0;

        @include media('<tablet'){
            flex-direction: column;
            padding: 0;
        }

        &:nth-child(odd){
            .item1{
                width: 58%;
        
                @include media('<Xtablet'){
                    width: 48%;
                }
        
                @include media('<tablet'){
                    width: 100%;
                }
            }
        
            .item2{
                width: 38%;
        
                @include media('<Xtablet'){
                    width: 48%;
                }

                @include media('<tablet'){
                    width: 100%;
                }
        
            }
        }
    }

    .img{
        height: 340px;
        width: 100%;
        border-radius: 5px;
        margin-bottom: 20px;

        @include media('<tablet'){
            height: 440px;
        }

        @include media('<phone'){
            height: 250px;
        }
    }

    .text{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        p{
            font-weight: bold;
            @include adaptiv-vaule("font-size", 28, 18, 1);
            line-height: 119%;
            padding-right: 15px;

            @include media('<phone'){
                padding: 0 15px;
                margin: 0 auto;
            }
        }

        span{
            font-size: 18px;
            font-weight: normal;
            color: $white;
            text-shadow: 0 0 1px #ffffff, 0 0 1px #ffffff, 0 0 100px #ffffff, 0 0 10px #15F2FD, 0 0 50px #15F2FD, 0 0 10px #15F2FD, 0 0 0px #15F2FD, 0 0 1px #15F2FD;
            white-space: nowrap;

            @include media('<phone'){
                display: none;
            }
        }
    }
}

/* -----------------------------portfolio-pages------------------------- */

.case{
    position: relative;
    @include adaptiv-vaule("padding-top", 315, 90, 1);
    padding-bottom: 35px;

    .big-text{
        top: 10%;
    }

    .container{
        position: relative;
        z-index: 2;
    }

    h1{
        @include adaptiv-vaule("font-size", 55, 36, 1);
        margin-bottom: 75px;
    }

    span{
        color: $purpule-text;
    }

    .wing{
        bottom: -130px;
    }
}

/* -----------------------------project------------------------- */

.project{
    padding: 35px 0 40px 0;

    h3{
        @include adaptiv-vaule("font-size", 30, 28, 1);
        margin-bottom: 10px;

        @include media('<phone'){
            text-align: center;
        }
    }

    p, li{
        @include adaptiv-vaule("font-size", 20, 16, 1);
        font-weight: 300;
        line-height: 1.5;
    }

    ul{
        margin: 0;
        padding: 0 0 0 40px;

        @include media('<phone'){
            padding: 0 0 0 20px;
        }
    }

    li{
        letter-spacing: -0.01em;
        color: $white;
    }

    .title{
        @include adaptiv-vaule("margin-bottom", 80 , 30, 1);
    }

    .block{
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;

        @include media('<Ltablet'){
            flex-direction: column;
        }

        @include media('<phone'){
            margin: 0 -15px;
            margin-bottom: 35px;
        }

        h3{
            @include adaptiv-vaule("font-size", 30, 22, 1);
            margin-bottom: 10px;

            @include media('<phone'){
                text-align: start;
            }
        }

        p{
            margin-bottom: 40px;
        }
    }

    .img-wrap{
        width: 65%;

        @include media('<desktop'){
            width: 60%;
        }

        @include media('<tablet'){
            width: 48%;
        }

        @include media('<Ltablet'){
            width: 100%;
            margin-bottom: 40px;
        }
    }

    .img-row{
        margin-bottom: 60px;

        @include media('<phone'){
            margin: 0 -15px;
            margin-bottom: 35px;
        }
    }

    .description{
        width: 28%;

        @include media('<desktop'){
            width: 37%;
        }

        @include media('<tablet'){
            width: 48%;
        }

        @include media('<Ltablet'){
            width: 100%;
        }

        @include media('<phone'){
            text-align: center;
            padding: 0 15px;
        }

        h3, p{
            @include media('<phone'){
                text-align: start;
            }
        }

        .main-btn{
            display: flex;

            @include media('<Ltablet'){
                display: inline-flex;
            }
        }
    }
    
    .feedback{

        .user{
            margin-bottom: 40px;
        }
    }

    .block-img{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 70px;

        @include media('<phone'){
            margin: 0 -15px;
            margin-bottom: 35px;
        }

        .img{
            @include adaptiv-vaule("height", 565, 450, 1);
            width: 49.7%;
            margin-bottom: 7px;
            border-radius: 3px;

            @include media('<tablet'){
                width: 100%;
            }
        }
    }

    .block2{
        display: flex;
        justify-content: space-between;

        @include media('<Xtablet'){
            flex-direction: column;
            margin-bottom: 35px;
        }

        @include media('<phone'){
            margin: 0 -15px;
        }
        
        .title{
            width: 46%;

            @include media('<Xtablet'){
                width: 100%;
                margin-bottom: 35px;
            }

            @include media('<phone'){
                padding: 0 15px;
            }
        }

        .img{
            height: 685px;
            width: 49%;

            @include media('<desktop'){
                height: 720px;
            }

            @include media('<Xtablet'){
                height: 450px;
                width: 100%;
            }
        }
    }

    .btn-wrap{
        display: flex;
        justify-content: center;
        margin-bottom: 35px;

        @include media('<tablet'){
            margin-bottom: 50px;
        }
    }

    .nextbtn-wrap{
        display: flex;
        justify-content: space-between;

        a{
            position: relative;
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 24px;

            @include media('<tablet'){
                font-size: 0;
                color: transparent;
            }

            &::after{
                content:'';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 2px;
                background-color: $white;
                transition: all .3s ease 0s;
            }

            &:hover{
                &::after{
                    width: 100%;
                }
            }

            &:first-child{
                @include media('<tablet'){
                    margin-left: 60px;
                }

                img{
                    margin-right: 30px;
                }
            }

            &:last-child{
                @include media('<tablet'){
                    margin-right: 60px;
                }

                img{
                    margin-left: 30px;
                }
            }
        }
    }
}

/* -----------------------------news------------------------- */

.news{
    position: relative;
    @include adaptiv-vaule("margin-bottom", 130 , 70, 1);
    min-height: 50vh;

    .container{
        @include media('<phone'){
            padding: 0;
        }
    }

    .wing{
        top: 50%;
    }

    .content{
        display: grid;
        grid-template-columns: 32% 32% 32%;
        justify-content: center;
        gap: 30px;

        @include media('<desktop'){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 0;
        }

        @include media('<Ltablet'){
            flex-direction: column;
        }
    }

    .item{
        position: relative;
        @include adaptiv-vaule("margin-bottom", 100 , 70, 1);

        @include media('<desktop'){
            width: 48%;
            max-width: 360px;
        }

        @include media('<Ltablet'){
            width: 100%;
            max-width: 100%;
        }

        p{
            font-weight: bold;
            font-size: 28px;
            line-height: 119%;
            margin-bottom: 20px;

            @include media('<phone'){
                padding: 0 15px;
            }
        }

        span{
            font-size: 15px;
            line-height: 115.5%;
            letter-spacing: 0.05em;
            color: $purpule-text;

            @include media('<phone'){
                padding: 0 15px;
            }
        }

        a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: $main-black;
            font-size: 0;
            color: transparent;
            opacity: 0;
            transition: all .3s ease 0s;

            &:hover{
                opacity: 50%;
            }
        }
    }

    .img{
        width: 100%;
        height: 360px;
        margin-bottom: 20px;
    }
}

/* -----------------------------news-pages------------------------- */

.news-pages{
    position: relative;
    padding-bottom: 50px;

    h3{
        font-weight: bold;
        font-size: 36px;
        @include adaptiv-vaule("margin-bottom", 80 , 30, 1);
        text-align: center;
    }

    .img{
        width: 100%;
        height: 480px;
        margin-bottom: 30px;

        @include media('<desktop'){
            height: 600px;
        }

        @include media('<tablet'){
            height: 450px;
        }
    }

    .discription{
        padding: 15px 0;

        p{
            font-weight: 300;
            font-size: 18px;
            line-height: 1.5;
        }
    }

    .block{
        display: flex;
        justify-content: space-between;

        @include media('<Xtablet'){
            flex-direction: column;
        }

        .discription{
            padding: 0;
            width: 48%;

            @include media('<Xtablet'){
                width: 100%;
            }
        }

        .img{
            width: 48%;
            height: 550px;

            @include media('<desktop'){
               height: 700px;
            }

            @include media('<Xtablet'){
                width: 100%;
                height: 450px;
            }
        }
    }

    .author{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 100px;

        @include media('<phone'){
            flex-direction: column;
        }

        .user{
            @include media('<phone'){
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .img{
            @include media('<phone'){
                margin: 0;
                margin-bottom: 20px;
            }
        }

        .name{
            @include media('<phone'){
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
            }
        }

        .data{
            font-size: 16px;
            line-height: 133%;
            color: $white;
        }
    }

    .btn-wrap{
        text-align: center;
    }

    .wing{
        bottom: 0;
        z-index: -1;
    }
}

/* -----------------------------contact------------------------- */

.contact{
    position: relative;
    @include adaptiv-vaule("padding-top", 100, 0, 1);
    @include adaptiv-vaule("padding-bottom", 190, 0, 1);

    .container{
        @include media('<phone'){
            padding: 0;
        }
    }

    .content{
        width: 100%;
        max-width: 950px;
        margin: 0 auto;
        padding: 60px;
        background: linear-gradient(114.28deg, hsla(0, 0%, 15%, 0.7) 0%, hsla(0, 0%, 8%, 0.7) 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 10px 28px rgba(75, 0, 129, 0.12);
        border-radius: 10px;

        @include media('<Stablet'){
            max-width: 90%;
            padding: 60px 30px;
        }

        @include media('<Ltablet'){
            max-width: 95%;
        }

        @include media('<phone'){
            max-width: 100%;
            padding: 30px;
        }
    }

    h4{
        text-align: center;
        margin-bottom: 50px;
    }

    .sub-title{
        font-size: 16px;
        margin-bottom: 20px;

        @include media('<Stablet'){
            text-align: center;
        }
    }

    .block{
        display: flex;

        @include media('<Stablet'){
            flex-direction: column;
            align-items: center;
        }
    }

    form{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 422px;
        margin-right: 60px;

        @include media('<Stablet'){
            margin: 0 0 30px 0;
        }
    }

    label{
        position: relative;
        width: 100%;
        margin-bottom: 20px;

        &::before, &::after{
            content:'';
            position: absolute;
            top: 0;
            height: 1px;
            background-color: #B4BEC8;
            z-index: 3;
        }

        &::before{
            width: 10px;
            left: 0;
        }

        &::after{
            width: 80%;
            right: 0;

            @include media('<phone'){
                @include adaptiv-vaule("width", 1000, 180, 1);
            }
        }

        p{
            position: absolute;
            top: -10px;
            left: 15px;
            margin: 0;
            padding: 0 5px;
            font-size: 16px;
            color: #878787;
        }
    }

    input, textarea{
        width: 100%;
        padding: 12px;
        font-size: 16px;
        color: $purpule-text;
        background-color: transparent;
        border: 1.2px solid #B4BEC8;
        box-sizing: border-box;
        border-radius: 4px;
        
        &::placeholder{
            color: #878787;
        }
    }
    
    input{
        position: relative;
        border-top: transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        &:focus{
            border: 1.2px solid #B4BEC8;
            border-top: transparent;
        }
    }

    textarea{
        height: 140px;
        margin-bottom: 40px;
    }

    .contact-btn{
        border: none;
        color: $white;
        font-weight: bold;
        font-size: 18px;
    }

    .info{
        display: flex;
        flex-direction: column;
        max-width: 48%;

        @include media('<Stablet'){
            align-items: center;
            max-width: 100%;
            width: 100%;
        }

        @include media('<phone'){
            align-items: flex-start;
        }
    }

    .info-item{
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        img{
            margin-right: 20px;
        }
    }

    .link{
        position: relative;

        &::after{
            content:'';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0;
            height: 2px;
            background-color: $purpule-text;
            border-radius: 40px;
            transition: all .3s ease 0s;
            box-shadow: 0px -5px 15px 2px $purpule-text;
            z-index: 2;  

            animation-duration: 5s;
            animation-iteration-count: infinite;
        }

        &:hover{
            &::after{
                width: 100%;
                animation-name: blick;
            }
        }

        @keyframes blick {
            0%{
                box-shadow: 0px -5px 15px 2px $purpule-text;
            }
            25%{
                box-shadow: 0px -5px 8px 2px $purpule-text;
            }
            50%{
                box-shadow: 0px -5px 15px 2px $purpule-text;
            }
            75%{
                box-shadow: 0px -5px 8px 2px $purpule-text;
            }
            100%{
                box-shadow: 0px -5px 15px 2px $purpule-text;
            }
        }
    }

    .social-links{
        display: flex;

        @include media('<Stablet'){
            align-self: center;
        }

        a{
            margin-right: 20px;
            animation-duration: .3s;

            @include media('<Stablet'){
                margin: 0 10px;
            }
            
            &:hover{
                animation-name: linksUp;
            }
        }

        @keyframes linksUp {
            0%{
                transform: translateY(0);
            }
            50%{
                transform: translateY(-5px);
            }
            100%{
                transform: translateY(0);
            }
        }
    }

    .wing{
        bottom: 0;
        z-index: -1;
    }
}