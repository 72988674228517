.testi{
    position: relative;
    padding: 40px 0;
    background-color: $purpule-bg;

    @include media('<tablet'){
        padding: 40px 0 100px 0;
    }

    .container{
        @include media('<tablet'){
            padding: 0;
        }
    }

    .content{
        display: flex;
        justify-content: space-between;

        @include media('<tablet'){
            flex-direction: column;
        }
    }

    .title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 400px;
        width: 45%;

        @include media('<tablet'){
            width: 100%;
            padding: 0 15px;
        }

        h2{
            @include media('<tablet'){
                margin-bottom: 33px;
            }
        }
    }

    .btn-wrap{
        @include media('<tablet'){
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
        }
    }

    .comments{
        width: 50%;
        max-width: 520px;

        @include media('<tablet'){
            width: 100%;
            max-width: 100%;
        }

        p{
            margin-bottom: 40px;
        }
    }

    .item{
        padding: 0 15px;
        margin-bottom: 33px;
    }
}

.user{
    display: flex;

    .img{
        width: 55px;
        height: 55px;
        border-radius: 50%;
        margin-right: 20px;
    }

    .name{

        p{
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5px;
        }

        span{
            font-size: 15px;
            color: $white;
        }
    }
}

.testi-slider{
    overflow: hidden;
}